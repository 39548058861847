import React, { useState, useEffect, useRef } from "react"
import { EmailSettings, ErrorMessages } from "../../constants"
import { useForm } from "react-hook-form"
import {
  TrvInput,
  TrvEmailInput,
  TrvTextArea
} from "trv-ebus-tcom-reactcomponents"
import { TdsAlert } from "@trv-tds/react"
import ContactUsEmailTemplate from "../email-templates/contactUsEmailTemplate"
import { v4 as uuidv4 } from "uuid"
import { Link } from "gatsby"
import { store } from "../../state/store"
import Recaptcha from "../../components/recaptcha/recaptcha"
import {emailHeaders, emailPayload} from '../email-templates/emailRequest'
import {
  recaptchaCalled,
  sendEmailPayloads,
  sentWithRecaptcha
} from "../../services/formsService"
import "../styles.scss"

const ContactUsForm = () => {
  const [isClient, setIsClient] = useState(false)
  const [isServiceError, setIsServiceError] = useState(false)
  const [isGoogleServiceError, setIsGoogleServiceError] = useState(false)
  const [isSubmissionInProgress, setIsSubmissionInProgress] = useState(false)
  const [emailBody, setEmailBody] = useState(null)
  const returnUrl = "/contact-us/thankyou"

  const alertRef = useRef(null)

  const state = store.getState()

  const recaptchaEnabled =
    state.config.RECAPTCHA_ENABLED === "true" ? true : false

  useEffect(() => {
    setIsClient(true)
  }, [])

  const {
    register,
    handleSubmit,
    formState: { errors }
  } = useForm()

  const googleServiceErrorHandler = () => {
    setIsGoogleServiceError(true)
  }

  const onSubmit = async (formdata) => {
    if (!isGoogleServiceError) {
      setIsSubmissionInProgress(true)
      const body = ContactUsEmailTemplate(formdata)
      let formPayload = {}
      formPayload = {
          email_body: {
            "topic": state.config.EEF_EVENT,
            "payload": {
                ...emailPayload('CONTACT_US', state.config, formdata),
                "metaData": [
                    {
                        "key": "InquirerName",
                        "value": `${formdata.firstname} ${formdata.lastname}`
                    },
                    {
                        "key": "Oganization",
                        "value": formdata.organization
                    },
                    {
                        "key": "Message",
                        "value": formdata.message
                    },
                    {
                        "key": "AppData",
                        "value": state.config.APP_CODE_EVENT_INQUIRY
                    },
                    {
                        "key": "sourceSiloRegion",
                        "value": state.config.EVENT_REGION
                    },
                    {
                        "key": "EmailDisplay",
                        "value": formdata.email
                    }
                ]
            },
            "headers": emailHeaders(state.config)
          }
        }
      
      if (recaptchaCalled()) {
        setEmailBody(formPayload)
      } else {
        const emailSent = await sendEmailPayloads(formPayload, returnUrl)
        if (!emailSent) {
          serviceErrorHandler()
        }
        setIsSubmissionInProgress(false)
      }
    } else {
      serviceErrorHandler()
    }
  }

  const submitWithRecaptcha = async (recaptchaResponse, siteType) => {
    const emailSent = await sentWithRecaptcha(
      recaptchaResponse,
      siteType,
      emailBody,
      returnUrl
    )

    if (!emailSent) {
      serviceErrorHandler()
    }
    setIsSubmissionInProgress(false)
  }

  const serviceErrorHandler = () => {
    setIsServiceError(true)
    window.scrollTo(0, alertRef.current.offsetTop - 100)
  }

  return isClient ? (
    <>
      <Link
        to="/claim-reporting"
        className="tds-button--primary tds-margin-top-xxx-small">
        Report a Claim
      </Link>
      <hr className="tds-margin-top-x-large" />
      <h2 className="tds-margin-top-x-large">Send a Message</h2>
      <p className="tds-margin-top-large">
        If you have questions about our products or services, please get in
        touch.
      </p>
      <p className="tds-margin-top-large">All fields are required.</p>
      {isServiceError && (
        <TdsAlert type="error" ref={alertRef}>
          {ErrorMessages.Service_Error}
        </TdsAlert>
      )}
      <div className="tds-row tds-margin-top-x-large">
        <div className="tds-col-xl-10">
          <form onSubmit={handleSubmit(onSubmit)} noValidate>
            {recaptchaEnabled && (
              <Recaptcha
                serviceCall={submitWithRecaptcha}
                siteType={state.config.RECAPTCHA_SITE_TYPE}
                googleServiceError={googleServiceErrorHandler}
              />
            )}
            <TrvInput
              register={register}
              name="firstname"
              label="First Name"
              required={true}
              errors={errors.firstname}
              maxLength="120"
              validityMessages={{
                required: ErrorMessages.Empty_FirstName
              }}
            />
            <TrvInput
              register={register}
              name="lastname"
              label="Last Name"
              required={true}
              errors={errors.lastname}
              maxLength="120"
              validityMessages={{
                required: ErrorMessages.Empty_LastName
              }}
            />
            <TrvInput
              register={register}
              name="organization"
              label="Organization"
              required={true}
              errors={errors.organization}
              maxLength="254"
              validityMessages={{
                required: ErrorMessages.Empty_Organization
              }}
            />
            <TrvEmailInput
              register={register}
              name="email"
              label="Email"
              required={true}
              errors={errors.email}
              maxLength="254"
              validityMessages={{
                required: ErrorMessages.Empty_Email,
                pattern: ErrorMessages.Invalid_Email
              }}
            />
            <TrvTextArea
              register={register}
              name="message"
              label="Message"
              rows={4}
              cols={20}
              required={true}
              errors={errors.message}
              validityMessages={{
                required: ErrorMessages.Empty_Message
              }}
            />
            <button
              className="tds-button--primary tds-margin-top-large"
              aria-busy={isSubmissionInProgress}
              type="submit">
              Send
            </button>
          </form>
        </div>
        <div className="tds-col-xl-2" />
      </div>
    </>
  ) : null
}

export default ContactUsForm
