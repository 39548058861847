import React from "react"
import ReactDOMServer from "react-dom/server"
import { EmailTableRow } from "./emailTemplate"

const ContactUsEmailTemplate = (formData) => {
  return ReactDOMServer.renderToString(
    <table>
      <EmailTableRow
        label="Name:"
        value={`${formData.firstname} ${formData.lastname}`}
      />
      <EmailTableRow label="Organization:" value={formData.organization} />
      <EmailTableRow label="Email:" value={formData.email} />
      <EmailTableRow label="Message:" value={formData.message} />
    </table>
  )
}

export default ContactUsEmailTemplate
